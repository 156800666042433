import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Line, ResponsiveContainer } from 'recharts';
import Linechart from '../../../../../components/Chart/LineChart';
import { filterItems } from '../../../../../utils/arrayProcessor';
import ReportTable from '../../../components/ReportTable/ReportTable';
import { AuditWiseOutletChannelPercent, fillColor, fundamentalList, modifyWeekYearWise } from '../../../config';
import { tableHeaderChange } from '../../table/OutletWiseScore/config';
import { BrandChainStyled } from './BrandChainStyled';
import { brandColor, dimension, getOutletInformtion, labelConfig, labelMappings, SOA, SOS } from './config';

const BrandChain = ({ bu, outletChannel }) => {
  const rScore = useSelector(state => state?.RscoreDatas?.rScore);
  const RSCORE_BU = filterItems(rScore, bu, 'bu');
  const OUTLET_CHANNEL = filterItems(RSCORE_BU, outletChannel, 'outlet_channel');

  // for fundamental table
  const GROUP_BY_OUTLET = _(OUTLET_CHANNEL).groupBy('outlet').value();
  let categoryArr = [];
  for (const property in GROUP_BY_OUTLET) {
    const outletWise = GROUP_BY_OUTLET[property];
    const fundamentalWise = _(outletWise).groupBy('fundamental').value();
    categoryArr.push({
      fundamental: fundamentalWise,
      isCategoryHeader: fundamentalWise['Share of shelf']?.every(item => item?.brand === null), //for dynamic table header i.e brand or category
      category: property,
      outletInformation: getOutletInformtion(GROUP_BY_OUTLET, property),
    });
  }

  // for line chart
  const P3MRscore = useSelector(state => state?.RscoreDatas?.P3MRscore);
  const RSCORE_BU_P3M = filterItems(P3MRscore, bu, 'bu');

  const filterOutletPercent = outlet => {
    const outletWise = filterItems(RSCORE_BU_P3M, outlet, 'outlet');
    const AUDIT_PERIOD_WISE = _(outletWise).groupBy('audit_period').value();
    const _AuditWiseOutletChannelPercent = AuditWiseOutletChannelPercent(AUDIT_PERIOD_WISE);
    return _AuditWiseOutletChannelPercent;
  };

  //for share of shelf table
  const SOSTable = useCallback(
    outlet => {
      const outletWise = filterItems(OUTLET_CHANNEL, outlet, 'outlet');
      const fundamentalWise = filterItems(outletWise, fundamentalList.SHARE_OF_SHELF, 'fundamental');
      const isCategoryHeader = fundamentalWise?.every(item => item?.brand === null); //for dynamic table header i.e brand or category
      const groupByLabel = !isCategoryHeader ? 'brand' : 'category';
      const CATEGORY_WISE_SOS = _(fundamentalWise)?.groupBy(groupByLabel).value();
      let arrySOS = [];
      for (const property in CATEGORY_WISE_SOS) {
        let pointScoredSum = 0;
        let pointsAllocatedSum = 0;
        let bonusAwarded = 0;
        let requiredJBP = 0;
        let allocated = 0;
        let audited = 0;

        CATEGORY_WISE_SOS[property]?.map(item => {
          pointScoredSum = pointScoredSum + item?.point_scored;
          pointsAllocatedSum = pointsAllocatedSum + item?.points_allocated;
          bonusAwarded = bonusAwarded + item?.bonus_point;
          requiredJBP += item?.required_jbp_criteria;
          allocated += item?.allocated_audit_point;
          audited += item?.audit_point;
        });
        const data = {
          property,
          pointScoredSum,
          pointsAllocatedSum,
          bonusAwarded,
          requiredJBP,
          allocated,
          audited,
        };
        arrySOS.push(data);
      }
      return arrySOS;
    },
    [OUTLET_CHANNEL],
  );

  // for share of display table
  const summationSOD = useCallback(
    outlet => {
      const outletWise = filterItems(OUTLET_CHANNEL, outlet, 'outlet');
      const fundamentalWise = filterItems(outletWise, fundamentalList.SHARE_OF_DISPLAY, 'fundamental');
      let pointsAllocatedSum = 0;
      let pointScoredSum = 0;
      let bonusAwarded = 0;
      let requiredJBP = 0;
      let allocated = 0;
      let audited = 0;
      let SOD_ARRAY = [];
      fundamentalWise?.map(item => {
        pointsAllocatedSum += item?.points_allocated;
        pointScoredSum += item?.point_scored;
        bonusAwarded += item?.bonus_point;
        requiredJBP += item?.required_jbp_criteria;
        allocated += item?.allocated_audit_point;
        audited += item?.audit_point;
      });
      SOD_ARRAY.push({ pointsAllocatedSum, pointScoredSum, bonusAwarded, requiredJBP, allocated, audited });
      return SOD_ARRAY;
    },
    [OUTLET_CHANNEL],
  );

  // for share of availability table
  const summationSOA = useCallback(
    outlet => {
      const outletWise = filterItems(OUTLET_CHANNEL, outlet, 'outlet');
      const fundamentalWise = filterItems(outletWise, fundamentalList.SHARE_OF_AVAILABILITY, 'fundamental');
      let pointsAllocatedSum = 0;
      let pointScoredSum = 0;
      let bonusAwarded = 0;
      let requiredJBP = 0;
      let allocated = 0;
      let audited = 0;
      let SOA_ARRAY = [];
      fundamentalWise?.map(item => {
        pointsAllocatedSum += item.points_allocated;
        pointScoredSum += item.point_scored;
        bonusAwarded += item.bonus_point;
        requiredJBP += item?.required_jbp_criteria;
        allocated += item?.allocated_audit_point;
        audited += item?.audit_point;
      });

      SOA_ARRAY.push({ pointsAllocatedSum, pointScoredSum, bonusAwarded, requiredJBP, allocated, audited });
      return SOA_ARRAY;
    },
    [OUTLET_CHANNEL],
  );

  const onImagesPage = (fundamental, item) => {
    const imageData = {
      fundamental,
      outlet: item?.category,
      bu,
      outletChannel,
      outletInformation: item?.outletInformation,
    };
    localStorage.setItem('image-data', JSON?.stringify(imageData));
    window.open('/r-score/fundamental-images', '_blank');
  };

  return (
    <>
      <BrandChainStyled>
        {categoryArr?.map(item => (
          <div>
            <h2 className="bbsm-title"> {item?.category}</h2>
            <div className="bbsm-wrap">
              <div className="bbsm-row">
                <div className="bbsm-individual">
                  <ResponsiveContainer width="100%" aspect={3}>
                    <Linechart
                      data={modifyWeekYearWise(filterOutletPercent(item?.category))}
                      dimensions={dimension}
                      isXAxis={true}
                      isYAxis={true}
                      legend={false}
                      cartesianGrid={true}
                      tooltip={false}
                      color={brandColor}
                      xAxisTitle="Score"
                      yAxisTitle="Weeks"
                      title="P3M Summary"
                      aspect={2.5}
                    >
                      <Line
                        type="monotone"
                        dataKey={`${outletChannel}`}
                        stroke={brandColor[`${outletChannel}`]}
                        strokeWidth={2}
                        fill={brandColor[`${outletChannel}`]}
                        label={{ position: 'top', fill: '#030913', fontSize: '12px', fontWeight: '500', dy: -5 }}
                        isAnimationActive={false}
                      />
                    </Linechart>
                  </ResponsiveContainer>
                </div>

                {item?.fundamental[fundamentalList.SHARE_OF_SHELF] && (
                  <div className="bbsm-individual">
                    <div className={`outlet-channel-${outletChannel.toLowerCase()}`}>
                      <ReportTable
                        datas={_.sortBy(SOSTable(item?.category), 'property')}
                        title="Share of Shelf"
                        labelConfig={item?.isCategoryHeader ? labelConfig[SOS] : tableHeaderChange(labelConfig[SOS])}
                        labelMappings={labelMappings[SOS]}
                        bgColor={fillColor(`${outletChannel?.toLowerCase()}bgColor`)}
                        baseBgColor={fillColor(`${outletChannel?.toLowerCase()}baseBgColor`)}
                        showModal={true}
                      />
                      <div className="view-images" onClick={() => onImagesPage(fundamentalList.SHARE_OF_SHELF, item)}>
                        View Images
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className="bbsm-row">
                {item?.fundamental[fundamentalList.SHARE_OF_AVAILABILITY] && (
                  <div className="bbsm-individual">
                    <div className={`outlet-channel-${outletChannel.toLowerCase()}`}>
                      <ReportTable
                        datas={_.sortBy(summationSOA(item?.category), 'property')}
                        title="On Shelf Availability"
                        labelConfig={labelConfig[SOA]}
                        labelMappings={labelMappings[SOA]}
                        bgColor={fillColor(`${outletChannel?.toLowerCase()}bgColor`)}
                        baseBgColor={fillColor(`${outletChannel?.toLowerCase()}baseBgColor`)}
                        showModal={true}
                      />
                      <div
                        className="view-images"
                        onClick={() => onImagesPage(fundamentalList.SHARE_OF_AVAILABILITY, item)}
                      >
                        View Images
                      </div>
                    </div>
                  </div>
                )}

                {item?.fundamental[fundamentalList.SHARE_OF_DISPLAY] && (
                  <div className="bbsm-individual">
                    <div className={`outlet-channel-${outletChannel.toLowerCase()}`}>
                      <ReportTable
                        datas={_.sortBy(summationSOD(item?.category), 'property')}
                        title="Share of Display"
                        labelConfig={labelConfig[SOA]}
                        labelMappings={labelMappings[SOA]}
                        bgColor={fillColor(`${outletChannel?.toLowerCase()}bgColor`)}
                        baseBgColor={fillColor(`${outletChannel?.toLowerCase()}baseBgColor`)}
                        showModal={true}
                      />
                      <div className="view-images" onClick={() => onImagesPage(fundamentalList.SHARE_OF_DISPLAY, item)}>
                        View Images
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        ))}
      </BrandChainStyled>
    </>
  );
};

export default BrandChain;
