import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { filterItems } from '../../../utils/arrayProcessor';
import PageHeader from '../../base/PageHeader';
import { PanelStyled } from '../../common/configuration';
import { rScoreFail, rScoreReq, rScoreSuccess } from '../redux/actions';
import { ImageStyled } from './ImageStyled';
import PropTypes from 'prop-types';
import withAlert from '../../../utils/composition/withAlert';
import { ALERT_TYPE } from '../../../v4/constants/AlertType';
import { Loading } from '../../../components';
import { PhotoSwipe } from '../../../v4/components';
import { fundamentalList, getbuIds } from '../config';
import {
  getThumbnailContent,
  getThumbnailContentSOSKU,
  options,
  outletImageTransformer,
  outletImageTransformerSOSKU,
} from './config';

const propTypes = {
  displayAlert: PropTypes.func.isRequired,
};

const Images = props => {
  const { getAllRscore, getSOSKUImages, displayAlert } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [SOSKUImages, setSOSKUImages] = useState([]);

  const dispatch = useDispatch();
  const rScore = useSelector(state => state?.RscoreDatas?.rScore);
  const imageData = JSON.parse(localStorage.getItem('image-data'));
  const { bu, fundamental, outlet, outletChannel, outletInformation } = imageData;
  const buIds = getbuIds();

  useEffect(() => {
    fundamental !== fundamentalList.SHARE_OF_AVAILABILITY && getBusinessRscoreData();
    fundamental === fundamentalList.SHARE_OF_AVAILABILITY && getAllSOSKUImages();
  }, []);

  const getOutletWiseData = () => {
    const RSCORE_BU = filterItems(rScore, bu, 'bu');
    const FUNDAMENTAL_WISE = filterItems(RSCORE_BU, fundamental, 'fundamental');
    const OUTLET_WISE = filterItems(FUNDAMENTAL_WISE, outlet, 'outlet');
    return OUTLET_WISE;
  };

  const outletImages = useMemo(() => getOutletWiseData(), [rScore]);

  const breadCrumbConfig = [
    {
      id: 1,
      title: outletChannel,
      active: true,
    },
    {
      id: 2,
      title: outlet,
      active: true,
    },

    {
      id: 3,
      title: fundamental?.replace(fundamentalList.SHARE_OF_AVAILABILITY, fundamentalList.ON_SHELF_AVAILABILITY),
      active: true,
    },
    {
      id: 4,
      title: 'Images',
      active: true,
    },
  ];

  const getBusinessRscoreData = () => {
    setIsLoading(true);
    const auditWeek = JSON.parse(localStorage.getItem('audit_week'));
    getAllRscore(
      {
        filter: {
          dateRange: {
            start: auditWeek?.firstDate || auditWeek?.startDate,
            end: auditWeek?.lastDate || auditWeek?.endDate,
          },
        },
        buIds,
      },

      {
        handleSuccess: res => {
          dispatch(rScoreReq());
          const response = res?.data?.getRscoreData;
          dispatch(rScoreSuccess(response));
          setIsLoading(false);
        },
        handleError: error => {
          onAPIRequestFailure(error);
          dispatch(rScoreFail(error));
          setIsLoading(false);
        },
      },
    );
  };

  const getBrandWiseSOSKU = arr => {
    const BRAND_WISE = _(arr).groupBy('brand').value();
    let arrBrandWise = [];
    for (const property in BRAND_WISE) {
      const brandWise = BRAND_WISE[property];
      arrBrandWise.push({
        fundamental: brandWise,
        brand: property,
      });
    }
    return arrBrandWise;
  };

  const getAllSOSKUImages = () => {
    setIsLoading(true);
    const auditWeek = JSON.parse(localStorage.getItem('audit_week'));
    const { buId, outletId } = outletInformation;
    getSOSKUImages(
      {
        filter: {
          dateRange: {
            start: auditWeek?.firstDate || auditWeek?.startDate,
            end: auditWeek?.lastDate || auditWeek?.endDate,
          },
        },
        buId,
        outletId,
      },
      {
        handleSuccess: res => {
          res?.data?.getSOSKUImages && setSOSKUImages(getBrandWiseSOSKU(res?.data?.getSOSKUImages));
          setIsLoading(false);
        },
        handleError: error => {
          onAPIRequestFailure(error);
          setIsLoading(false);
        },
      },
    );
  };

  const onAPIRequestFailure = error => {
    displayAlert(ALERT_TYPE.DANGER, error);
  };

  return (
    <>
      {isLoading && <Loading title="Loading data... Please wait!" />}
      <ImageStyled>
        <div className="section-header">
          <PanelStyled>
            <PageHeader breadCrumb={breadCrumbConfig} />
          </PanelStyled>
        </div>
        <div className="page-body">
          {(fundamental === fundamentalList.SHARE_OF_AVAILABILITY ? SOSKUImages : outletImages)?.map(item => (
            <div style={{ margin: '25px 0' }}>
              {fundamental === fundamentalList.SHARE_OF_AVAILABILITY
                ? item?.brand && <div className="category-type">{item?.brand}</div>
                : item?.category && <div className="category-type">{item?.category}</div>}
              {fundamental === fundamentalList.SHARE_OF_AVAILABILITY ? (
                <PhotoSwipe
                  items={outletImageTransformerSOSKU(item?.fundamental)}
                  options={options}
                  thumbnailContent={getThumbnailContentSOSKU}
                />
              ) : (
                <PhotoSwipe
                  items={outletImageTransformer(item?.images, item?.audited_date)}
                  options={options}
                  thumbnailContent={getThumbnailContent}
                />
              )}
            </div>
          ))}
        </div>
      </ImageStyled>
    </>
  );
};

Images.propTypes = propTypes;

export default withAlert()(Images);
